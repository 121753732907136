import { DeleteOutlined, LockFilled } from "@ant-design/icons";
import { Button, Table, Typography } from "antd";
import { useEffect, useState } from "react";

import * as API from "../../../api/API";
import LoaderRaw from "../../../utils/LoaderRaw";
import openNotification from "../../../utils/notification";

const { Text } = Typography;

function DuplicatesClients() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [clientListUpdate, setClientListUpdate] = useState(false);
  const [displayHeader, setDisplayHeader] = useState(false);
  const [displayDelete, setDisplayDelete] = useState(false);
  const [dataSource, setDataSource] = useState();
  const [selectionType] = useState("checkbox");

  useEffect(() => {
    (async () => {
      let resA = await API.getAccountingFirms();
      resA = await resA.json();
      let accountingFirms = [];
      resA.forEach(function (obj) {
        let newObj = {};
        newObj.accountingFirmId = obj._id;
        newObj.accountingFirmName = obj.name;
        newObj.accountingFirmActive = obj.active;
        accountingFirms.push(newObj);
      });

      let clients = await API.getDuplicatedClients();
      clients = await clients.json();

      // merge accounting firm et clients
      let merge = clients.map((t1) => ({
        ...t1,
        ...accountingFirms.find((t2) => t2.accountingFirmId === t1.accountingFirmId),
      }));

      // renommage de certaines propriétés du nouveau tableau
      merge.forEach(function (obj) {
        obj.key = obj._id;
        delete obj.date;
      });

      setClientListUpdate(false);
      setDataSource(merge);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientListUpdate === true]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRows.length === 1) {
        setSelectedRows(selectedRows);
        setDisplayHeader(true);
        setDisplayDelete(true);
      } else if (selectedRows.length >= 1) {
        setSelectedRows(selectedRows);
        setDisplayHeader(true);
        setDisplayDelete(true);
      } else {
        setSelectedRows(null);
        setDisplayHeader(false);
        setDisplayDelete(false);
      }
    },
  };

  const columns = [
    {
      title: "Nom",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => text,
    },
    {
      title: "Cabinet Comptable",
      dataIndex: ["accountingFirmName", "accountingFirmActive"],
      align: "center",
      sorter: (a, b) => a.accountingFirmName?.localeCompare(b.accountingFirmName),
      render: (text, record) => {
        return (
          <>
            <>{record.accountingFirmName}</>

            {record.accountingFirmActive === false ? (
              <>
                {" "}
                <LockFilled title="Cabinet désactivé" style={{ color: "#c32028" }} />
              </>
            ) : null}
          </>
        );
      },
    },
    {
      title: "Adresse",
      dataIndex: "address",
      sorter: (a, b) => a.address?.localeCompare(b.address),
      render: (text, record) => text,
    },
    {
      title: "Ville",
      dataIndex: "city",
      sorter: (a, b) => a.city?.localeCompare(b.city),
      render: (text, record) => text,
    },
    {
      title: "Code postal",
      dataIndex: "postalCode",
      sorter: (a, b) => a.postalCode?.localeCompare(b.postalCode),
      render: (text, record) => text,
    },
    {
      title: "SIREN",
      dataIndex: "siren",
      sorter: (a, b) => a.siren - b.siren,
      render: (text, record) => text,
    },
    {
      title: "Dernière maj",
      dataIndex: "updateDate",
      sorter: (a, b) => new Date(b.updateDate) - new Date(a.updateDate),
      render: (text, record) => new Date(text)?.toLocaleDateString(),
    },
  ];

  const handleDelete = () => {
    if (
      window.confirm(
        `Voulez-vous supprimer les clients suivants :\r ${selectedRows.map((r) => r.name)}?`
      )
    ) {
      selectedRows.forEach((elt) => {
        (async () => {
          const resp = await API.deleteClient(elt._id);
          if (resp.status === 200) openNotification("success", "Client supprimé back + mongo");
          else openNotification("error", "Client non supprimé");
          setClientListUpdate(true);
          setDisplayHeader(false);
        })();
      });
    }
  };

  return (
    <div className="userDashboard">
      <br />
      <Text className="section-title">Liste des doublons clients</Text>
      <div className="blue-bar"></div>
      <br />
      {displayHeader ? (
        <div className="header-table">
          {displayDelete ? (
            <div
              style={{
                display: "inline-block",
                float: "right",
                padding: "9px",
              }}
            >
              <Button
                className="call-action-btn"
                key="delete"
                onClick={handleDelete}
                icon={<DeleteOutlined />}
              >
                Supprimer
              </Button>
            </div>
          ) : null}
        </div>
      ) : null}

      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        columns={columns}
        dataSource={dataSource}
        size="small"
        bordered
        pagination={{ defaultPageSize: 10, showSizeChanger: true }}
        loading={{ indicator: <LoaderRaw />, spinning: !dataSource }}
      />
    </div>
  );
}

export default DuplicatesClients;
