import {
  ArrowRightOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockFilled,
} from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Row, Typography } from "antd";
import { PasswordInput } from "antd-password-input-strength";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as API from "../../api/API";
import { ReactComponent as LogoSeshaWhite } from "../../assets/images/logo-sesha-white.svg";
import logoSesha from "../../assets/images/logo-sesha.svg";
import { seshaLogoModifier } from "./../nav/logoModifier";

import pdfCGV from "../../assets/pdf/cgv.pdf";
import { selectUser } from "../../slices/userSlice";

import passwordValidator from "password-validator";

import "./Login.css";

const { Title, Text } = Typography;

function ModifyPwd(props) {
  const user = useSelector(selectUser);

  const [pwd, getPwd] = useState("");
  const [modifiedSeshaLogo, setModifiedSeshaLogo] = useState("");

  useEffect(() => {
    (async () => {
      setModifiedSeshaLogo(btoa(await seshaLogoModifier(logoSesha, process.env.REACT_APP_ENV)));
    })();
  }, []);

  var schema = new passwordValidator();
  schema
    .is()
    .min(12)
    .is()
    .max(100)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits()
    .has()
    .symbols()
    .has()
    .not()
    .spaces();

  const onPwdChangeFinish = (values) => {
    (async () => {
      let res = await API.changePwd(user._id, {
        newPwd: values.password,
        passwordConf: values.confirm,
      });
      if (res.status === 200) {
        await API.putUser(user._id, {
          firstCo: false,
          ...(values.cgv && { cgv: Date.now() }),
        });

        let response = await API.postGenerateNewToken({
          token: localStorage.getItem("token"),
        });
        if (response.status === 200) {
          response = await response.json();
          localStorage.setItem("token", response.token);
          props.history.push({
            pathname: "/homepage",
          });
        } else {
          console.log("Error while generating a new token");
        }
      } else {
        console.log("Error updating user password");
      }
    })();
  };

  const onFinishChangeFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const Mailto = ({ email, subject, children }) => {
    return <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ""}`}>{children}</a>;
  };

  return (
    <div className="modify">
      <Row justify="center" className="login-row">
        <Col xs={16} sm={16} md={8} lg={8} xl={8} className="left-box">
          <div>
            <img
              hidden={modifiedSeshaLogo === ""}
              src={"data:image/svg+xml;base64," + modifiedSeshaLogo}
              alt="Sesha"
            ></img>
          </div>
          <div className="login-left-1">
            <Title level={3}>Modification de votre mot de passe</Title>
          </div>
          <Form
            className="send-pwd-form"
            onFinish={onPwdChangeFinish}
            onFinishFailed={onFinishChangeFailed}
          >
            <div className="login-left-3">
              <Text style={{ fontWeight: "bold" }}>Saisissez votre nouveau mot de passe</Text>
              <Form.Item
                className="pwd-form-item"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Merci de renseigner un mot de passe",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (schema.validate(value) === true) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          <>
                            <p>Votre mot de passe doit comporter :</p>
                            <div>
                              <ul>
                                <li>Au moins 12 caractères</li>
                                <li>Au moins 1 chiffre</li>
                                <li>Au moins 1 majuscule et 1 minuscule</li>
                                <li>Au moins 1 caractère spécial</li>
                                <li>Sans espace</li>
                              </ul>
                            </div>
                          </>
                        );
                      }
                    },
                  }),
                ]}
                hasFeedback
              >
                <PasswordInput
                  className="pwd-input"
                  placeholder="Mot de passe"
                  autoFocus
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  value={pwd}
                  onChange={(event) => getPwd(event.target.value)}
                />
              </Form.Item>
              <Text style={{ fontWeight: "bold" }}>Confirmez votre nouveau mot de passe</Text>
              <Form.Item
                name="confirm"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Merci de confirmer votre nouveau mot de passe",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject("Il y a une erreur dans un mot de passe");
                    },
                  }),
                ]}
              >
                <Input.Password
                  className="pwd-input"
                  placeholder="Mot de passe"
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </Form.Item>
              {!user.cgv ? (
                <Form.Item
                  name="cgv"
                  valuePropName="checked"
                  rules={[
                    {
                      required: true,
                      transform: (value) => value || undefined,
                      type: "boolean",
                      message: "Vous devez accepter les CGV afin de pouvoir continuer",
                    },
                  ]}
                >
                  <Checkbox>
                    J’accepte les{" "}
                    <a href={pdfCGV} target="blank" className="cgv">
                      Conditions générales de vente du service
                    </a>
                  </Checkbox>
                </Form.Item>
              ) : null}
            </div>
            <div className="submit-modify">
              <Form.Item>
                <Button htmlType="submit" className="submit call-action-btn">
                  Continuer <ArrowRightOutlined />
                </Button>
              </Form.Item>
            </div>
          </Form>
          <div className="help">
            <Text className="need-help">Besoin d'assistance</Text>
            <Mailto className="login-txt" email="support@sesha.expert" subject="Assistance Sesha">
              Contactez-nous
            </Mailto>
          </div>
        </Col>
        <Col xs={16} sm={16} md={13} lg={13} xl={13} className="login-box">
          <div className="secured-txt">
            <LockFilled style={{ color: "white" }} className="lock" />
            <span className="secure">Espace sécurisé</span>
          </div>
          <div className="login-logo-white-parent">
            <div className="login-logo-white-child">
              <LogoSeshaWhite />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ModifyPwd;
