import { Tabs } from "antd";
import { useSelector } from "react-redux";
import { selectAccountingFirm } from "../../../slices/accountingFirmSlice";
import PaymentRequired from "../../unauthorized/PaymentRequired";
import FeatureSettings from "./FeatureSettings";
import OtherModels from "./OtherModels";

const { TabPane } = Tabs;

function AnalysisConfigTabs(props) {
  const accountingFirm = useSelector(selectAccountingFirm);

  return (
    <div className="analysis-settings custom-block">
      {!accountingFirm.proAbonoFeatures?.includes("analysis_config") ? (
        <PaymentRequired {...props} page={"analysis_config"} />
      ) : (
        <Tabs defaultActiveKey="1" tabPosition="left" className="custom-tabs">
          <TabPane tab={<span>Analyse</span>} key="1">
            <FeatureSettings {...props} type="ANALYSIS" />
          </TabPane>
          <TabPane tab="Pièces manquantes" key="2">
            <FeatureSettings {...props} type="MISSING_DOC" />
          </TabPane>
          <TabPane tab='Pièces "Autres"' key="3">
            <OtherModels />
          </TabPane>
          <TabPane tab="Encours clients" key="4">
            <FeatureSettings {...props} type="OUTSTANDINGS_CLIENTS" />
          </TabPane>
          <TabPane tab="Encours fournisseurs" key="5">
            <FeatureSettings {...props} type="OUTSTANDINGS_PROVIDERS" />
          </TabPane>
        </Tabs>
      )}
    </div>
  );
}

export default AnalysisConfigTabs;
