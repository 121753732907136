import Client from "components/client/Client";
import Statistics from "components/manager/Statistics.js";
import Oauth2 from "components/oauth2/Oauth2";
import Settings from "components/settings/Settings.js";
import HostedPage from "components/subscription/HostedPage.js";
import Subscribe from "components/subscription/Subscribe.js";
import OffersIframe from "components/subscription/offers/Offers-Iframe.js";
import Offers from "components/subscription/offers/Offers.js";
import AdminTabs from "./components/admin/AdminTabs";
import NewIndexAnalysis from "./components/analysis/NewIndexAnalysis.js";
import Dashboard from "./components/dashboard/Dashboard.js";
import Homepage from "./components/homepage/Homepage.js";
import Import from "./components/import/Import.js";
import ImportDataProvider from "./components/import/ImportDataProvider.js";
import Login from "./components/login/Login.js";
import ModifyPwd from "./components/login/ModifyPwd.js";
import Logout from "./components/logout/Logout.js";
import PortfolioView from "./components/manager/ManagerView";
import Nav from "./components/nav/Nav.js";
import Version from "./components/version/Version.js";

const routes = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/logout/:origin",
    component: Logout,
  },
  {
    path: "/logout",
    component: Logout,
  },
  {
    path: "/modifypassword",
    features: ["ACCESS_TO_SESHA", "ACCESS_TO_FORMS"],
    component: ModifyPwd,
  },
  {
    path: "/version",
    component: Version,
  },
  {
    path: "/import/:clientId",
    features: ["ADD_CLIENT"],
    component: Import,
  },
  {
    path: "/import",
    features: ["ADD_CLIENT"],
    component: Import,
  },
  {
    path: "/importdataprovider",
    features: ["ADD_CLIENT"],
    component: ImportDataProvider,
  },
  {
    path: "/analysis/:clientId/:clientRemoteId",
    features: ["ACCESS_TO_SESHA"],
    component: NewIndexAnalysis,
  },
  {
    path: "/client/:token",
    component: Client,
  },
  {
    path: "/homepage",
    features: ["ACCESS_TO_SESHA"],
    component: Homepage,
  },
  {
    path: "/nav",
    features: ["ACCESS_TO_SESHA"],
    component: Nav,
  },
  {
    path: "/admin",
    features: ["MANAGE_USERS_AND_RIGHTS", "ADMINISTRATION"],
    component: AdminTabs,
  },
  {
    path: "/portfolio",
    features: ["ACCESS_TO_SESHA"],
    component: PortfolioView,
  },
  {
    path: "/subscribe",
    exact: true,
    features: ["ADMINISTRATION"],
    component: Subscribe,
  },
  {
    path: "/offers",
    exact: true,
    features: ["ADMINISTRATION"],
    component: Offers,
  },
  {
    path: "/offers-iframe",
    exact: true,
    component: OffersIframe,
  },
  {
    path: "/statistics",
    exact: true,
    features: ["ACCESS_TO_SESHA"],
    component: Statistics,
  },
  {
    path: "/dashboard",
    exact: true,
    features: ["SUPERADMIN_READ", "SUPERADMIN_WRITE", "SUPERADMIN_TECH"],
    component: Dashboard,
  },
  {
    path: "/settings",
    exact: true,
    features: ["ACCESS_TO_SESHA", "ACCESS_TO_FORMS"],
    component: Settings,
  },
  {
    path: "/hosted",
    exact: true,
    component: HostedPage,
  },
  {
    path: "/oauth2/:code",
    component: Oauth2,
  },
  {
    path: "/",
    component: Login,
  },
];

export default routes;
