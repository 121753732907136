import {
  ApiOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { Alert, Button, Form, Input, Modal, Select, Tooltip } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as API from "../../api/API";
import { selectAccountingFirm } from "../../slices/accountingFirmSlice";
import logAction from "../../utils/logActions";
import openNotification from "../../utils/notification";

const { Option } = Select;

function CreateUser(props) {
  const accountingFirm = useSelector(selectAccountingFirm);

  const [form] = Form.useForm();
  const [createUser, setCreateUser] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [email, setEmail] = useState("");
  const [userID, setUserID] = useState("");

  const onFinish = (values) => {
    var randompwd = Math.random().toString(36).slice(-12);
    values.password = randompwd;
    values.accountingFirmId = accountingFirm._id;
    values.passwordConf = randompwd;

    (async () => {
      const res = await API.postUser(values);
      if (res.status === 404) {
        setAlertMessage("Un utilisateur existe déjà avec cette adresse mail.");
        setAlert(true);
      } else if (res.status === 403) {
        setAlertMessage("Nombre maximum d'utilisateurs atteint.");
        setAlert(true);
      } else {
        setCreateUser(false);
        props.updateUser(true);
        const response = await API.userCreationMail({
          email: values.email,
          firstName: values.firstName,
          pwd: randompwd,
        });
        props.setUpdateRoles(!props.updateRoles);
        if (response.status === 200) {
          logAction(50, 1);
          openNotification("success", "Utilisateur créé avec succès");
        } else openNotification("error", "Erreur lors de la création de l'utilisateur");
      }
    })();
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const validateMessages = {
    // eslint-disable-next-line no-template-curly-in-string
    required: "${label} doit être renseigné",
    types: {
      email: "L'email n'est pas valide",
    },
  };

  return (
    <div className="create-user create-user-admin">
      <Button
        className="call-action-btn"
        onClick={(e) => {
          e.preventDefault();
          setCreateUser(!createUser);
        }}
        icon={<UserAddOutlined />}
        style={{ marginLeft: "-10%" }}
      >
        Ajouter un utilisateur
      </Button>
      <Modal
        title="Création d'un nouvel utilisateur"
        className="create-user-modal"
        open={createUser}
        onCancel={(e) => {
          e.preventDefault();
          setCreateUser(false);
          setEmail("");
          setUserID("");
        }}
        afterClose={() => form.resetFields()}
        footer={null}
        forceRender
      >
        {alert === true ? (
          <Alert
            className="alert-duplicate"
            message={alertMessage}
            type="error"
            closable
            banner
            onClose={() => setAlert(false)}
          />
        ) : null}
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          labelAlign="left"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateMessages={validateMessages}
        >
          <Form.Item
            name="firstName"
            label="Prénom"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Nom"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          {accountingFirm.connectedRights ? (
            <>
              <Form.Item
                name="email"
                label="Adresse email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Merci de renseigner l'adresse email",
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setUserID("");
                  }}
                  suffix={
                    <Tooltip title="Matcher à l'ID de l'outil de production">
                      <Button
                        icon={<ApiOutlined />}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setUserID(
                            props.userRightsList.find(
                              (elt) => email !== "" && elt.user_mail === email
                            )?.user_id
                          );
                        }}
                        style={{ border: "0px" }}
                      />
                    </Tooltip>
                  }
                />
              </Form.Item>

              {userID && userID !== "" ? (
                <div style={{ margin: "-20px 0px 20px 0", textAlign: "right" }}>
                  <CheckCircleOutlined style={{ color: "#4caf50" }} />
                  <span style={{ marginLeft: "5px" }}>Identifiant correspondant: </span>
                  <span style={{ fontWeight: "700" }}>{userID}</span>
                </div>
              ) : userID === undefined ? (
                <div style={{ margin: "-20px 0px 20px 0" }}>
                  <div style={{ textAlign: "right", marginBottom: "3px" }}>
                    <CloseCircleOutlined style={{ color: "#c32028" }} />
                    <span style={{ marginLeft: "5px" }}>
                      Identifiant non retrouvé automatiquement.
                    </span>
                  </div>
                  <Alert
                    message="Si l’utilisateur a été créé dans votre outil de production après la dernière
                    mise à jour, veuillez mettre à jour les droits dans l’écran précédent."
                    type="info"
                    showIcon
                    style={{ fontSize: "12px" }}
                  />
                </div>
              ) : null}
            </>
          ) : (
            <Form.Item
              name="email"
              label="Adresse email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Merci de renseigner l'adresse email",
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}

          <Form.Item
            className="select-role"
            name="newRole"
            extra="Les administrateurs peuvent gérer les comptes utilisateurs
            et l'affichage des dossiers client."
            label="Droits associés"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select>
              {props.roleList.map((role) => (
                <Option value={role._id}>{role.label}</Option>
              ))}
            </Select>
          </Form.Item>
          {accountingFirm.connectedRights ? (
            <Form.Item className="select-rights" name="rights" label="Ajouter des droits">
              <Select mode="multiple">
                {props.userRightsList.map((elt, i) => {
                  return (
                    <Option key={i} value={elt.user_id}>
                      {elt.user_id}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          ) : null}

          <Form.Item wrapperCol={{ span: 24 }}>
            <Button
              block
              className="call-action-btn modal-submit-btn"
              htmlType="submit"
              icon={<UserAddOutlined />}
            >
              Créer
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default CreateUser;
