const getCssForGeneration = () => {
  return `

:root {
  --var-neg: #f1cdcd;
  --var-pos: #cdf1d8;
  --gray0: #6e6e73;
}

* {
  font-family: "Roboto";
}


body {
  display: flex;
  flex-direction: row;
  justify-content: end;
}
body > div {
  padding: 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 30px;
  flex-wrap: wrap;
  width: fit-content;
  max-width: 340px;
}

/* Cells */
body > div > div {
  width: calc(50% - 15px);
  display: flex;
  flex-direction: column;
  gap: 7px;
}

/* title */
body > div > div > div:nth-child(1) {
  text-align: end;
}

/* Value n-1 */
body > div > div > span {
  text-align: end;
  font-size: 1.1em;
  font-weight: 400;
  color: var(--gray0);
}

/* Individual cell */
.cell-div {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: end;
  align-items: center;
}

/* Value */
.cell-div > span {
  font-weight: 600;
  font-size: 1.1em;
  text-wrap: nowrap;
}

/* Variation */
.variation-div {
  padding: 3px 10px;
  background-color: var(--var-neg);
  border-radius: 25px;
  font-size: 0.8em;
  color: rgb(36, 36, 36);
}

.var-pos {
  background-color: var(--var-pos);
}

  `;
};

export { getCssForGeneration };
