import { LoadingOutlined } from "@ant-design/icons";
import { Badge, Col, Row, Spin, Tabs } from "antd";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { TimeMeasurement } from "utils/timeMeasurement";
import { selectAccountingFirm } from "../../slices/accountingFirmSlice";
import logAction from "../../utils/logActions";
import PaymentRequired from "../unauthorized/PaymentRequired";
import "./Analysis.css";
import AnalysisComp from "./analysis/Analysis";
import AttentionPoints from "./keyPoints/AttentionPoints";
import MissingDocuments from "./missing/MissingDocuments";
import Outstandings from "./outstandings/Outstandings";
import ReceivedDocuments from "./received/ReceivedDocuments";

const { TabPane } = Tabs;

const spinIcon = (
  <LoadingOutlined
    style={{
      fontSize: 10,
      marginLeft: "20px",
      marginBottom: "20px",
    }}
    spin
  />
);

function IndexAnalysisTabs(props) {
  const clientId = props.match.params.clientId;

  const accountingFirm = useSelector(selectAccountingFirm);

  const [refreshMissingDoc, setRefreshMissingDoc] = useState(false);

  //badges tabs
  const [displayBadges, setDisplayBadges] = useState(false);
  const [badgeKeyPoints, setBadgeKeyPoints] = useState();
  const [badgeReceived, setBadgeReceived] = useState();
  const [badgeMissing, setBadgeMissing] = useState();
  const [badgeOutstandingClients, setBadgeOutstandingClients] = useState();
  const [badgeOutstandingProviders, setBadgeOutstandingProviders] = useState();
  const [activeKey, setActiveKey] = useState(window.location.hash.replace("#", "") ?? "1");
  const activeKeyRef = useRef(activeKey);
  const timeMeasurement = new TimeMeasurement();

  const onChange = (activeKey) => {
    setActiveKey((previous) => {
      if (previous === "2") logAction(224, timeMeasurement.getElapsedTime(), clientId);
      if (previous === "3") logAction(233, timeMeasurement.getElapsedTime(), clientId);
      timeMeasurement.start();
      activeKeyRef.current = activeKey;
      window.location.hash = `#${activeKey}`;
      return activeKey;
    });
  };

  useEffect(() => {
    return () => {
      if (activeKeyRef.current === "2") logAction(224, timeMeasurement.getElapsedTime(), clientId);
      if (activeKeyRef.current === "3") logAction(233, timeMeasurement.getElapsedTime(), clientId);
    };
  }, []);

  useEffect(() => {
    setActiveKey(props.activeKey);
  }, [props.activeKey]);

  useEffect(() => {
    if (props.resetBadges) {
      setDisplayBadges(false);
      setBadgeKeyPoints();
      setBadgeMissing();
      setBadgeOutstandingClients();
      setBadgeOutstandingProviders();
    }
  }, [props.resetBadges]);

  // display badges when they are all ready
  useEffect(() => {
    if (
      badgeKeyPoints !== undefined &&
      badgeReceived !== undefined &&
      badgeMissing !== undefined &&
      ((badgeOutstandingClients !== undefined && badgeOutstandingProviders !== undefined) ||
        !accountingFirm.proAbonoFeatures?.includes("outstandings"))
    )
      setDisplayBadges(true);
  }, [
    badgeKeyPoints,
    badgeMissing,
    badgeOutstandingClients,
    badgeOutstandingProviders,
    badgeReceived,
    accountingFirm.proAbonoFeatures,
  ]);

  return (
    <div>
      <Row justify="center" className="analysis-row">
        <Col xs={22} sm={22} md={22} lg={22} xl={22} className="analysis-box">
          <Tabs defaultActiveKey={activeKey} onChange={onChange} activeKey={activeKey}>
            <TabPane
              tab={
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    logAction(21, 1, clientId);
                  }}
                >
                  Analyse
                </span>
              }
              key="1"
              forceRender
            >
              <AnalysisComp
                {...props}
                wait={props.wait}
                date={props.date}
                sendData={props.setSendData}
                analysisData={props.setAnalysisData}
                setSelectedMailType={props.setSelectedMailType}
              />
            </TabPane>
            <TabPane
              className="tab-container"
              tab={
                <Badge
                  count={
                    displayBadges ? (
                      badgeKeyPoints
                    ) : (
                      <Spin className="badge-spin" indicator={spinIcon} />
                    )
                  }
                  className="badge-count"
                >
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      logAction(25, 1, clientId);
                    }}
                  >
                    Points d'attention
                  </span>
                </Badge>
              }
              key="2"
              forceRender
            >
              <AttentionPoints
                {...props}
                wait={props.wait}
                date={props.date}
                setBadgeKeyPoints={setBadgeKeyPoints}
                activeKeyAttentionPoints={(data) => setActiveKey(data)}
              />
            </TabPane>

            <TabPane
              tab={
                <Badge
                  count={
                    displayBadges ? (
                      badgeMissing
                    ) : (
                      <Spin className="badge-spin" indicator={spinIcon} />
                    )
                  }
                  className="badge-count"
                >
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      logAction(22, 1, clientId);
                    }}
                  >
                    Pièces manquantes
                  </span>
                </Badge>
              }
              key="3"
              className="tab-container"
              forceRender
            >
              <MissingDocuments
                {...props}
                wait={props.wait}
                date={props.date}
                badgeMissing={badgeMissing}
                setBadgeMissing={setBadgeMissing}
                nbr={props.setTotalNbr}
                amount={props.setMissingAmount}
                filteredOp={props.setFilteredOperations}
                others={props.setOthers}
                setMissing={props.setMissing}
                setSelectedMailType={props.setSelectedMailType}
                refreshMissingDoc={refreshMissingDoc}
                setRefreshMissingDoc={setRefreshMissingDoc}
              />
            </TabPane>

            <TabPane
              className="tab-container"
              tab={
                <Badge
                  count={
                    displayBadges ? (
                      badgeReceived
                    ) : (
                      <Spin className="badge-spin" indicator={spinIcon} />
                    )
                  }
                  className="badge-count"
                >
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      logAction(26, 1, clientId);
                    }}
                  >
                    Pièces reçues
                  </span>
                </Badge>
              }
              key="4"
              forceRender
            >
              <ReceivedDocuments
                {...props}
                wait={props.wait}
                setBadgeReceived={setBadgeReceived}
                setReceived={props.setReceived}
                refreshMissingDoc={refreshMissingDoc}
                setRefreshMissingDoc={setRefreshMissingDoc}
              />
            </TabPane>
            <TabPane
              className="tab-container"
              tab={
                <Badge
                  count={
                    displayBadges ? (
                      badgeOutstandingClients
                    ) : (
                      <Spin className="badge-spin" indicator={spinIcon} />
                    )
                  }
                  className="badge-count"
                >
                  <Badge
                    count={"Pro"}
                    style={{
                      marginRight: badgeOutstandingClients > 0 ? "32px" : "0px",
                      visibility: !accountingFirm.proAbonoFeatures
                        ? "hidden"
                        : !accountingFirm.proAbonoFeatures?.includes("outstandings")
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        logAction(23, 1, clientId);
                      }}
                    >
                      Encours clients
                    </span>
                  </Badge>
                </Badge>
              }
              forceRender
              key="5"
            >
              {!accountingFirm.proAbonoFeatures?.includes("outstandings") ? (
                <PaymentRequired {...props} page={"outstandings"} />
              ) : (
                <Outstandings
                  {...props}
                  wait={props.wait}
                  date={props.date}
                  type="clients"
                  setBadge={(data) => {
                    setBadgeOutstandingClients(data);
                    props.setNbrOutstandingClients(data);
                  }}
                  setAmountOutstandingsClients={(data) => props.setOutstandingsClientsAmount(data)}
                  setSelectedMailType={props.setSelectedMailType}
                  selectedOperationsClients={props.setSelectedOperationsClients}
                />
              )}
            </TabPane>
            <TabPane
              className="tab-container"
              tab={
                <Badge
                  count={
                    displayBadges ? (
                      badgeOutstandingProviders
                    ) : (
                      <Spin className="badge-spin" indicator={spinIcon} />
                    )
                  }
                  className="badge-count"
                >
                  <Badge
                    count={"Pro"}
                    style={{
                      marginRight: badgeOutstandingProviders > 0 ? "32px" : "0px",
                      visibility: !accountingFirm.proAbonoFeatures
                        ? "hidden"
                        : !accountingFirm.proAbonoFeatures?.includes("outstandings")
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        logAction(24, 1, clientId);
                      }}
                    >
                      Encours fournisseurs
                    </span>
                  </Badge>
                </Badge>
              }
              forceRender
              key="6"
            >
              {!accountingFirm.proAbonoFeatures?.includes("outstandings") ? (
                <PaymentRequired {...props} page={"outstandings"} />
              ) : (
                <Outstandings
                  {...props}
                  wait={props.wait}
                  date={props.date}
                  type="providers"
                  setBadge={(data) => {
                    setBadgeOutstandingProviders(data);
                    props.setNbrOutstandingProviders(data);
                  }}
                  setAmountOutstandingsProviders={(data) =>
                    props.setOutstandingsProvidersAmount(data)
                  }
                  setSelectedMailType={props.setSelectedMailType}
                  selectedOperationsClients={props.setSelectedOperationsProviders}
                />
              )}
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
}
export default IndexAnalysisTabs;
