// data providers features
const CLIENT_IMPORT = [
  "ACD",
  "CEGID QUADRA",
  "CEGID EXPERT v1",
  "CEGID EXPERT",
  "AGIRIS",
  "AGIRIS old",
  "MYUNISOFT",
  "FULLL",
  "CEGID LOOP",
  "HORUS",
  "INQOM",
];
const DAILY_UPDATE = ["ACD", "CEGID QUADRA", "MYUNISOFT", "FULLL", "HORUS", "INQOM"];
const NAVBAR_UPDATE = [
  "ACD",
  "CEGID QUADRA",
  "CEGID EXPERT v1",
  "AGIRIS old",
  "MYUNISOFT",
  "FULLL",
  "HORUS",
  "INQOM",
];
const USER_CREDENTIALS = ["ACD"];
const ADMIN_CREDENTIALS = [
  "CEGID QUADRA",
  "CEGID EXPERT",
  "CEGID EXPERT v1",
  "MYUNISOFT",
  "CEGID LOOP",
];
const FIRM_CREDENTIALS = ["SAGE"];
const BLOCK_CLIENT_EDIT = [
  "ACD",
  "CEGID QUADRA",
  "CEGID EXPERT",
  "CEGID EXPERT v1",
  "SAGE",
  "AGIRIS",
  "AGIRIS old",
  "MYUNISOFT",
  "FULLL",
  "CEGID LOOP",
];
const CONNECTED_RIGHTS = [
  "CEGID QUADRA",
  "CEGID EXPERT",
  "CEGID EXPERT v1",
  "AGIRIS",
  "CEGID LOOP",
  "FULLL",
  "MYUNISOFT",
  "ACD",
];

const hasFeature = (fecMode, dataProvider, feature) => {
  if (fecMode) return false;

  const featureList = {
    CLIENT_IMPORT,
    DAILY_UPDATE,
    NAVBAR_UPDATE,
    USER_CREDENTIALS,
    ADMIN_CREDENTIALS,
    FIRM_CREDENTIALS,
    BLOCK_CLIENT_EDIT,
    CONNECTED_RIGHTS,
  };

  return featureList[feature]?.includes(dataProvider) || false;
};

export { hasFeature as default };
