import {
  BarChartOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  LineChartOutlined,
  ManOutlined,
  MinusOutlined,
  PlusOutlined,
  TeamOutlined,
  UserOutlined,
  WomanOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  List,
  Modal,
  Popover,
  Radio,
  Row,
  Select,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useEffect, useRef, useState } from "react";
import * as API from "../../../api/API";
import logAction from "../../../utils/logActions";
import openNotification from "../../../utils/notification";
import CustomIndicator from "./CustomIndicator";
import CustomSelect from "./CustomSelect";
import IndicDescription from "./IndicDescription";
import Order from "./Order.js";

const { Paragraph } = Typography;

const indicName = {
  ca: "Chiffre d'affaires",
  marge: "Marge",
  masse_sal: "Masse salariale",
  treso: "Trésorerie nette",
  treso_dispo: "Trésorerie disponible",
  charges_ext: "Charges externes",
  result: "Résultat",
};

function AnalysisFeatureForm(props) {
  const [form] = Form.useForm();

  const [currentTemplate, setCurrentTemplate] = useState(props.selectedTemplate);
  const [isChanging, setIsChanging] = useState(false);
  const [clientsUsingTemplate, setClientUsingTemplate] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [customIndicator, setCustomIndicator] = useState(false);
  const [maxAttained, setMaxAttained] = useState(false);
  const [isIndicDelModalOpen, setIsIndicDelModalOpen] = useState(false);
  const [indicatorName, setIndicatorName] = useState("");
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [indicToDelete, setIndicToDelete] = useState("");
  const [indicHasBeenAdded, setIndicHasBeenAdded] = useState(false);
  const [indicsToHide, setIndicsToHide] = useState([]);

  const inputRef = useRef(null);
  const addIndicRef = useRef(null);

  const scrollDown = () => {
    const timer = setTimeout(() => {
      addIndicRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
    }, 300);

    return () => clearTimeout(timer);
  };

  useEffect(() => {
    setCurrentTemplate({ ...props.selectedTemplate });
  }, [props.selectedTemplate, props.type]);

  useEffect(() => {
    setMaxAttained(currentTemplate?.templateAnalysis?.filter((e) => !indicName[e.id]).length >= 10);
    setCustomIndicator(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTemplate?.templateAnalysis?.length]);

  useEffect(() => {
    (async () => {
      if (!props.selectedTemplate.sesha) {
        let res = await API.getClientsTemplate(props.selectedTemplate._id, props.type);
        res = await res.json();
        setClientUsingTemplate(res);
      }
    })();
  }, [props.selectedTemplate, props.type]);

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTemplate]);

  const toggleVisibility = (id) => {
    setIndicsToHide((prevIndics) => {
      const newIndics = prevIndics.includes(id)
        ? prevIndics.filter((indicId) => indicId !== id)
        : [...prevIndics, id];

      setIsChanging(true);
      return newIndics;
    });
  };

  useEffect(() => {
    const hiddenIndics = currentTemplate?.templateAnalysis
      .filter((indic) => !indic.visible && indicName[indic.id] && indic.id !== "treso_dispo")
      .map((indic) => indic.id);

    setIndicsToHide(hiddenIndics);
  }, [currentTemplate]);

  const onValuesChange = () => setIsChanging(true);

  const onSubmit = (values) => {
    (async () => {
      let data = {
        name: values.name,
        type: props.type,
        default: currentTemplate.default,
        templateAnalysis: [],
      };

      currentTemplate?.templateAnalysis.forEach((el) => {
        let analysisConfig = {
          id: el.id,
          name: values[el.name],
          chart: values[el.id + "chart"],
          number: values[el.id + "number"],
          gender: values[el.id + "gender"],
          rule_ca: values[el.id + "rule_ca"],
          rule_1: values[el.id + "rule_1"],
          add_accounts: values[el.id + "addAccounts"],
          remove_accounts: values[el.id + "removeAccounts"],
          remove_journals: values[el.id + "removeJournals"],
          description: values[el.id + "description"],
          order: el.order,
        };

        if (indicName[el.id] && el.id !== "treso_dispo") {
          analysisConfig.visible = indicsToHide.includes(el.id) ? false : true;
        }

        data.templateAnalysis.push(analysisConfig);
      });

      const res = await API.putAnalysisConfigTemplate(currentTemplate._id, data);
      if (res.status === 200) {
        openNotification("success", "Votre modèle a été sauvegardé");
        props.setSelectedTemplate(await res.json());
        props.setReloadTemplates(true);
      } else {
        openNotification("error", "Erreur lors de l'enregistrement du modèle");
      }
      indicHasBeenAdded && logAction(205, 1);
      setIndicHasBeenAdded(false);
    })();
  };

  const deleteTemplate = () => {
    (async () => {
      clientsUsingTemplate.forEach((client) => {
        API.putClient(client._id, { analysisIndics: [], tableOrder: [] });
      });

      const res = await API.deleteAnalysisConfigTemplate(currentTemplate._id);
      if (res.status === 200) {
        logAction(90, -1);
        props.setSelectedTemplate(null);
        props.setReloadTemplates(true);
        openNotification("success", "Votre modèle a été supprimé");
        window.scroll(0, 0);
      } else {
        openNotification("error", "Erreur lors de la suppression");
      }
    })();
  };

  const onClickDelete = () => {
    if (clientsUsingTemplate.length) setVisibleModal(true);
    else deleteTemplate();
  };

  const onReset = () => {
    setIsChanging(true);
    props.setSelectedTemplate({
      ...props.selectedTemplate,
      templateAnalysis: props.defaultTemplates.find((el) => el.type === props.type)
        .templateAnalysis,
    });
  };

  const handleTextChange = (indic, newText) => {
    setCurrentTemplate({
      ...currentTemplate,
      templateAnalysis: currentTemplate.templateAnalysis?.map((el) =>
        el.id === indic.id ? { ...el, name: newText } : el
      ),
    });
    setIsChanging(true);
  };

  return (
    <div className="analysis-type-form">
      <Form
        form={form}
        layout="horizontal"
        className="user-profile-form"
        onValuesChange={onValuesChange}
        onFinish={onSubmit}
        initialValues={{
          ...currentTemplate.templateAnalysis?.reduce((acc, curr) => {
            return {
              ...acc,
              [curr.name]: curr.name,
              [curr.id + "description"]: curr.description,
              [curr.id + "removeJournals"]: curr.remove_journals,
              [curr.id + "addAccounts"]: curr.add_accounts,
              [curr.id + "removeAccounts"]: curr.remove_accounts,
              [curr.id + "chart"]: curr.chart,
              [curr.id + "number"]: curr.number,
              [curr.id + "gender"]: curr.gender,
              [curr.id + "rule_ca"]: curr.rule_ca,
            };
          }, {}),
          name: currentTemplate?.name,
          title: currentTemplate.title,
          margeaddAccounts: ["Chiffre d'affaires"],
          margerule_1:
            currentTemplate?.templateAnalysis?.find((el) => el.id === "marge")?.rule_1 || false,
        }}
      >
        <Form.Item
          name="name"
          label={<EditOutlined style={{ fontSize: "20px" }} />}
          className="type-form-title"
        >
          <Input
            bordered={true}
            className={"form-input template-name-input"}
            disabled={currentTemplate.sesha}
          />
        </Form.Item>
        {currentTemplate.sesha ? null : (
          <>
            <Popover
              content={
                maxAttained ? (
                  <>
                    Vous avez atteint la limite de 10 indicateurs personnalisés. <br />
                    Supprimer un indicateur pour en créer un nouveau.
                  </>
                ) : null
              }
              placement="top"
            >
              <Button
                className={
                  maxAttained ? "analysis-indicator-btn-up" : "call-action-btn modal-submit-btn"
                }
                onClick={() => {
                  setCustomIndicator(!customIndicator);
                  scrollDown();
                }}
                style={{ width: "180px", marginBottom: "30px", borderRadius: "16px" }}
                disabled={maxAttained}
              >
                + Ajouter un indicateur
              </Button>
            </Popover>
            <Button
              className="other-action-btn modal-submit-btn"
              onClick={() => setIsOrderModalOpen(true)}
              style={{ marginBottom: "30px", marginLeft: "20px" }}
            >
              Réorganiser les indicateurs
            </Button>
            <Modal
              className="vpf-modal vpf-modal-index order-modal indic-order-modal"
              title="Modifier l’ordre des indicateurs"
              open={isOrderModalOpen}
              onCancel={(e) => {
                e.preventDefault();
                setIsOrderModalOpen(false);
              }}
              footer={false}
              destroyOnClose={true}
              width={600}
              height={500}
            >
              <Order
                {...props}
                setIsOrderModalOpen={setIsOrderModalOpen}
                currentTemplate={currentTemplate}
                setCurrentTemplate={setCurrentTemplate}
              />
            </Modal>
            <Button
              className="other-action-btn cancel-btn"
              onClick={onReset}
              style={{ position: "absolute", top: 0, right: "3%" }}
            >
              Rétablir le modèle Sesha
            </Button>

            <Tooltip title="Supprimer le modèle" color="#b12e2e">
              <Button
                className={
                  currentTemplate.default
                    ? "template-delete-btn-disabled"
                    : "call-action-btn template-delete-btn-new"
                }
                onClick={onClickDelete}
                disabled={currentTemplate.default}
                shape="circle"
                icon={<DeleteOutlined />}
                style={{ position: "relative", bottom: "131.5px", right: "0px" }}
              />
            </Tooltip>
          </>
        )}
        {(
          currentTemplate?.templateAnalysis || [
            {
              id: "ca",
              add_accounts: [],
              remove_accounts: [],
              remove_journals: [],
              chart: "C",
              visible: true,
            },
            {
              id: "marge",
              add_accounts: [],
              remove_accounts: [],
              remove_journals: [],
              rule_1: true,
              chart: "L",
              visible: true,
              description:
                "Dans le modèle par défaut: en cas d'absence de stock, l'analyse est établie à stock constant.",
            },
            {
              id: "masse_sal",
              add_accounts: [],
              remove_accounts: [],
              remove_journals: [],
              chart: "L",
              visible: true,
              description:
                "Dans le modèle par défaut: salaires, traitements et charges sociales (dirigeants inclus).",
            },
            {
              id: "treso",
              add_accounts: [],
              remove_accounts: [],
              remove_journals: [],
              chart: "L",
              visible: true,
            },
            {
              id: "treso_dispo",
              add_accounts: [],
              remove_accounts: [],
              remove_journals: [],
              //visible: true,
            },
            {
              id: "charges_ext",
              add_accounts: [],
              remove_accounts: [],
              remove_journals: [],
              chart: "L",
              visible: true,
            },
            {
              id: "result",
              add_accounts: [],
              remove_accounts: [],
              remove_journals: [],
              chart: "L",
              visible: true,
            },
          ]
        )
          .sort((a, b) => a.order - b.order)
          .map((indic, i) => {
            return (
              <div key={i} style={{ marginBottom: "50px" }}>
                <Row>
                  <Col style={{ minHeight: "53.6px" }}>
                    <Form.Item
                      name={indic.name || indicName[indic.id]}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Veuillez entrer un nom d'indicateur",
                      //     validator: (_, value) => {
                      //       return currentTemplate?.templateAnalysis
                      //         .filter((e) => e.name !== indic.name)
                      //         .map((e) => ({
                      //           name: e.name || indicName[e.id],
                      //         }))
                      //         .filter((e) => value === e.name).length > 1
                      //         ? Promise.reject(new Error("Ce nom existe déjà"))
                      //         : Promise.resolve();
                      //     },
                      //   },
                      // ]}
                      style={{ height: "5px" }}
                    >
                      <Paragraph
                        ref={inputRef}
                        editable={{
                          triggerType:
                            currentTemplate.sesha || indicName[indic.id] ? "none" : "text",
                          onChange: (newText) => handleTextChange(indic, newText),
                        }}
                        style={{
                          width: "100%",
                          height: "0px",
                        }}
                        className={"template-name-input section-title"}
                      >
                        {indic.name || indicName[indic.id]}
                      </Paragraph>
                    </Form.Item>
                    <div className="blue-bar"></div>
                  </Col>
                  <span style={{ display: "flex", position: "relative", top: "3px" }}>
                    {currentTemplate.sesha || indic.id === "treso_dispo" ? null : (
                      <Popover
                        placement="top"
                        content="graphique en barres / graphique en courbes"
                        className="info-popover"
                        overlayClassName="navbar-popover-overlay"
                      >
                        <Form.Item name={indic.id + "chart"} className="indic-btn-pos">
                          <Radio.Group defaultValue={indic.chart} buttonStyle="solid">
                            <div className="graph-btn">
                              <Radio.Button
                                value="C"
                                style={{
                                  borderBottomLeftRadius: "10px",
                                  borderTopLeftRadius: "10px",
                                }}
                              >
                                <div style={{ marginLeft: "-3px", marginTop: "-3px" }}>
                                  <BarChartOutlined />
                                </div>
                              </Radio.Button>
                              <Radio.Button
                                value="L"
                                style={{
                                  borderBottomRightRadius: "10px",
                                  borderTopRightRadius: "10px",
                                }}
                              >
                                <div style={{ marginLeft: "-5px", marginTop: "-3px" }}>
                                  <LineChartOutlined />
                                </div>
                              </Radio.Button>
                            </div>
                          </Radio.Group>
                        </Form.Item>
                      </Popover>
                    )}
                    {!indicName[indic.id] ? (
                      <>
                        <Popover
                          placement="top"
                          content="singulier / pluriel"
                          className="info-popover"
                          overlayClassName="navbar-popover-overlay"
                        >
                          <Form.Item name={indic.id + "number"} className="indic-btn-pos">
                            <Radio.Group defaultValue={""} buttonStyle="solid">
                              <div className="graph-btn">
                                <Radio.Button
                                  value="S"
                                  style={{
                                    borderBottomLeftRadius: "10px",
                                    borderTopLeftRadius: "10px",
                                  }}
                                >
                                  <div style={{ marginLeft: "-3px", marginTop: "-3px" }}>
                                    <UserOutlined />
                                  </div>
                                </Radio.Button>
                                <Radio.Button
                                  value="P"
                                  style={{
                                    borderBottomRightRadius: "10px",
                                    borderTopRightRadius: "10px",
                                  }}
                                >
                                  <div style={{ marginLeft: "-5px", marginTop: "-3px" }}>
                                    <TeamOutlined />
                                  </div>
                                </Radio.Button>
                              </div>
                            </Radio.Group>
                          </Form.Item>
                        </Popover>
                        <Popover
                          placement="top"
                          content="masculin / féminin"
                          className="info-popover"
                          overlayClassName="navbar-popover-overlay"
                        >
                          <Form.Item name={indic.id + "gender"} className="indic-btn-pos">
                            <Radio.Group defaultValue={""} buttonStyle="solid">
                              <div className="graph-btn">
                                <Radio.Button
                                  value="M"
                                  style={{
                                    borderBottomLeftRadius: "10px",
                                    borderTopLeftRadius: "10px",
                                  }}
                                >
                                  <div style={{ marginLeft: "-3px", marginTop: "-3px" }}>
                                    <ManOutlined />
                                  </div>
                                </Radio.Button>
                                <Radio.Button
                                  value="F"
                                  style={{
                                    borderBottomRightRadius: "10px",
                                    borderTopRightRadius: "10px",
                                  }}
                                >
                                  <div style={{ marginLeft: "-5px", marginTop: "-3px" }}>
                                    <WomanOutlined />
                                  </div>
                                </Radio.Button>
                              </div>
                            </Radio.Group>
                          </Form.Item>
                        </Popover>
                        <Tag
                          className="indic-btn-pos"
                          style={{
                            height: "20px",
                            marginLeft: "15px",
                            borderRadius: "7px",
                            top: "-4px",
                          }}
                        >
                          indicateur personnalisé
                        </Tag>
                      </>
                    ) : null}
                  </span>
                  {currentTemplate?.templateAnalysis[i]?.name ? (
                    <div style={{ marginLeft: "auto", right: "30px", position: "relative" }}>
                      <Button
                        shape="circle"
                        icon={<DeleteOutlined />}
                        style={{ marginLeft: "10px", marginTop: "-10px", top: "-3px" }}
                        onClick={() => {
                          setIsIndicDelModalOpen(true);
                          setIndicToDelete(indic.id);
                        }}
                      />
                    </div>
                  ) : null}
                  {props.selectedTemplate.sesha ||
                  !indicName[indic.id] ||
                  indic.id === "treso_dispo" ? null : (
                    <div style={{ marginLeft: "auto", right: "30px", position: "relative" }}>
                      <Popover
                        placement="top"
                        content={
                          indicsToHide.includes(indic.id)
                            ? "Afficher l'indicateur"
                            : "Masquer l'indicateur"
                        }
                        // className="info-popover"
                        overlayClassName="navbar-popover-overlay"
                      >
                        <Button
                          shape="circle"
                          icon={
                            indicsToHide.includes(indic.id) ? (
                              <EyeInvisibleOutlined />
                            ) : (
                              <EyeOutlined />
                            )
                          }
                          style={{ marginLeft: "10px", marginTop: "-10px", top: "-3px" }}
                          onClick={() => {
                            toggleVisibility(indic.id);
                          }}
                        />
                      </Popover>
                    </div>
                  )}
                </Row>
                <Form.Item name={indic.id + "description"} style={{ marginBottom: "16px" }}>
                  <IndicDescription
                    {...props}
                    name={indic.id + "description"}
                    indic={indic}
                    currentTemplate={currentTemplate}
                    setCurrentTemplate={setCurrentTemplate}
                    setIsChanging={setIsChanging}
                  />
                </Form.Item>
                <div className="type-form-para">
                  <p>
                    Racines de comptes
                    <Popover
                      placement="right"
                      content="Personnalisez les racines de comptes utilisées dans le calcul de l’indicateur."
                      className="info-popover"
                      overlayClassName="info-popover-overlay"
                    >
                      <span>
                        <InfoCircleOutlined />
                      </span>
                    </Popover>
                  </p>
                  <Form.Item name={indic.id + "addAccounts"} label="+">
                    {indic.id === "marge" ? (
                      <Select
                        mode="tags"
                        disabled
                        className={"form-input add-tags-disabled"}
                        notFoundContent={null}
                      />
                    ) : (
                      <CustomSelect
                        name={indic.id + "addAccounts"}
                        cssClassName={"form-input add-tags"}
                        template={currentTemplate}
                      />
                    )}
                  </Form.Item>
                  <Form.Item name={indic.id + "removeAccounts"} label="-">
                    <CustomSelect
                      name={indic.id + "removeAccounts"}
                      cssClassName={"form-input remove-tags"}
                      template={currentTemplate}
                    />
                  </Form.Item>
                  {indic.id === "marge" ? (
                    <Form.Item name={indic.id + "rule_1"} valuePropName="checked">
                      <Checkbox className={"checkbox-rule-1"} disabled={currentTemplate.sesha}>
                        Si Somme (3) = 0, alors ne pas prendre en compte le compte 603 dans le
                        calcul.
                      </Checkbox>
                    </Form.Item>
                  ) : null}
                  <p>
                    Journaux
                    <Popover
                      placement="right"
                      content="Choisissez les journaux à exclure."
                      className="info-popover"
                      overlayClassName="info-popover-overlay"
                    >
                      <span>
                        <InfoCircleOutlined />
                      </span>
                    </Popover>
                  </p>
                  <Form.Item name={indic.id + "removeJournals"} label="-">
                    <CustomSelect
                      name={indic.id + "removeJournals"}
                      cssClassName={"form-input remove-tags"}
                      template={currentTemplate}
                    />
                  </Form.Item>
                  {currentTemplate?.templateAnalysis[i]?.name ? (
                    <Form.Item name={indic.id + "rule_ca"} valuePropName="checked">
                      <Checkbox className={"checkbox-rule-1"}>
                        <span>Comparer l’indicateur par rapport au chiffre d’affaires</span>
                      </Checkbox>
                    </Form.Item>
                  ) : null}
                </div>
              </div>
            );
          })}
        {currentTemplate.sesha ? null : customIndicator ? null : (
          <Form.Item
            className="save-template-item"
            style={{ position: "relative", float: "right", marginTop: "20px" }}
          >
            <Button
              className={
                !isChanging
                  ? "call-action-btn modal-submit-bnt disable-submit"
                  : "call-action-btn modal-submit-btn"
              }
              htmlType="submit"
              disabled={!isChanging}
            >
              Sauvegarder
            </Button>
          </Form.Item>
        )}
      </Form>
      <br />
      <br />
      {currentTemplate.sesha ? null : (
        <div ref={addIndicRef}>
          <Row>
            <Col span={24}>
              <Popover
                content={
                  maxAttained ? (
                    <>
                      Vous avez atteint la limite de 10 indicateurs personnalisés. <br />
                      Supprimer un indicateur pour en créer un nouveau.
                    </>
                  ) : null
                }
                placement="right"
              >
                <Button
                  className={maxAttained ? "analysis-indicator-btn" : "indicator-submit-btn"}
                  onClick={() => setCustomIndicator(!customIndicator)}
                  disabled={maxAttained}
                  style={{ position: "relative", bottom: "44px" }}
                >
                  {customIndicator ? <MinusOutlined /> : <PlusOutlined />}
                </Button>
              </Popover>

              {customIndicator && !maxAttained ? (
                <>
                  <CustomIndicator
                    {...props}
                    currentTemplate={currentTemplate}
                    setCurrentTemplate={setCurrentTemplate}
                    setCustomIndicator={setCustomIndicator}
                    indicName={indicName}
                    indicatorName={indicatorName}
                    setIndicatorName={setIndicatorName}
                    setIsChanging={setIsChanging}
                    setIndicHasBeenAdded={setIndicHasBeenAdded}
                  />
                </>
              ) : null}
            </Col>
          </Row>
        </div>
      )}
      <Modal
        className="reset-modal"
        title="Avertissement avant suppression"
        centered
        open={visibleModal}
        onCancel={(e) => {
          e.preventDefault();
          setVisibleModal(false);
        }}
        footer={[
          <Button
            key="confirm"
            className="call-action-btn template-delete-btn"
            style={{ float: "unset" }}
            danger
            onClick={(e) => {
              e.preventDefault();
              setVisibleModal(false);
              deleteTemplate();
            }}
          >
            Confirmer la suppression
          </Button>,
          <Button
            key="cancel"
            className="other-action-btn"
            onClick={(e) => {
              e.preventDefault();
              setVisibleModal(false);
            }}
          >
            Annuler
          </Button>,
        ]}
        width={500}
      >
        <p>
          Attention, ce modèle est utilisé sur un ou plusieurs dossiers client. Le supprimer
          réinitialisera ces dossiers avec le modèle par défaut
        </p>
        <p>Liste des clients utilisant ce modèle:</p>
        <List
          bordered
          dataSource={clientsUsingTemplate?.map((el) => el.name)}
          renderItem={(item) => (
            <List.Item>
              <Typography.Text mark></Typography.Text> {item}
            </List.Item>
          )}
        />
      </Modal>

      <Modal
        centered
        open={isIndicDelModalOpen}
        onCancel={() => setIsIndicDelModalOpen(!isIndicDelModalOpen)}
        width={400}
        closeIcon={<></>}
        footer={[
          <div style={{ marginTop: "25px" }}>
            <Button
              key="cancel"
              className="other-action-btn"
              onClick={(e) => {
                e.preventDefault();
                setIsIndicDelModalOpen(false);
              }}
            >
              Annuler
            </Button>

            <Button
              key="confirm"
              style={{ float: "unset", borderRadius: "30px" }}
              danger
              onClick={(e) => {
                e.preventDefault();
                setIsIndicDelModalOpen(false);
                (async () => {
                  const res = await API.postDeleteIndicator({
                    indicId: indicToDelete,
                    templateId: currentTemplate._id,
                  });
                  if (res.status === 200) {
                    openNotification("success", "Votre modèle a été mis à jour");
                    const indics = currentTemplate.templateAnalysis.filter(
                      (elt) => elt.id !== indicToDelete
                    );
                    setCurrentTemplate({
                      ...currentTemplate,
                      templateAnalysis: indics,
                    });
                    setIndicToDelete("");
                  } else {
                    openNotification("error", "Erreur lors de la mise à jour du modèle");
                  }
                  logAction(205, -1);
                })();
              }}
            >
              Supprimer
            </Button>
          </div>,
        ]}
      >
        <p>
          <ExclamationCircleOutlined
            style={{
              color: "orange",
              marginRight: "10px",
              fontSize: "20px",
              top: "2px",
              position: "relative",
            }}
            size={"large"}
          />
          Êtes-vous sûr de vouloir supprimer cet indicateur ?
        </p>
      </Modal>
    </div>
  );
}

export default AnalysisFeatureForm;
