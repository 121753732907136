import { Col, Row, Tabs } from "antd";
import { EFeatures } from "enums/EFeatures";
import { useState } from "react";
import { hasFeatures } from "utils/security";
import Footer from "../footer/Footer.js";
import Nav from "../nav/Nav.js";
import "./Dashboard.css";
import DashboardFirms from "./accountingFirms/AccountingFirmDashboard.js";
import DashboardOverview from "./overview/OverviewDashboard.js";
import NewsParams from "./params/NewsParams";
import Supervisor from "./supervisor/Supervisor";
import Support from "./support/client/Support";
import Email from "./support/email/Email";
import Sentry from "./support/user/Sentry";
import DashboardUsers from "./users/UsersDashboard.js";

const { TabPane } = Tabs;

function Dashboard(props) {
  const [activeKey, setActiveKey] = useState("1");

  const onChange = (activeKey) => {
    setActiveKey(activeKey);
  };

  return (
    <div className="dashboard">
      <Nav {...props} />
      <Row justify="center" className="admin-content">
        <Col xs={22} sm={22} md={22} lg={22} xl={22} className="admin-box">
          <Tabs defaultActiveKey={activeKey} onChange={onChange} activeKey={activeKey}>
            <TabPane className="tab-container" tab={<span>Cabinets Comptables</span>} key="1">
              <DashboardFirms />
            </TabPane>
            <TabPane className="tab-container" tab={<span>Utilisateurs</span>} key="2">
              <DashboardUsers />
            </TabPane>
            <TabPane className="tab-container" tab={<span>Synthèse</span>} key="3">
              <DashboardOverview />
            </TabPane>
            {hasFeatures([EFeatures.SUPERADMIN_WRITE, EFeatures.SUPERADMIN_TECH]) && (
              <TabPane className="tab-container" tab={<span>Affichage Nouveautés</span>} key="5">
                <NewsParams />
              </TabPane>
            )}
            {hasFeatures([EFeatures.SUPERADMIN_TECH]) && (
              <>
                <TabPane className="tab-container" tab={<span>Superviseur</span>} key="6">
                  <Supervisor />
                </TabPane>
                <TabPane className="tab-container" tab={<span>Support</span>} key="7">
                  <Support />
                </TabPane>
                <TabPane className="tab-container" tab={<span>Sentry</span>} key="8">
                  <Sentry />
                </TabPane>
                <TabPane className="tab-container" tab={<span>Email</span>} key="9">
                  <Email />
                </TabPane>
              </>
            )}
          </Tabs>
        </Col>
      </Row>
      <Footer />
    </div>
  );
}

export default Dashboard;
