import {
  ArrowRightOutlined,
  CalendarOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Typography,
  Upload,
} from "antd";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import hasFeature from "utils/dataProviderFeatures";
import * as API from "../../api/API";
import { selectAccountingFirm } from "../../slices/accountingFirmSlice";
import { selectClient } from "../../slices/clientSlice";
import logAction from "../../utils/logActions";
import openNotification from "../../utils/notification";
import Footer from "../footer/Footer.js";
import Nav from "../nav/Nav.js";
import "./Import.css";

const { confirm } = Modal;
const { Option } = Select;

const { Title, Text } = Typography;

function Import(props) {
  const accountingFirm = useSelector(selectAccountingFirm);
  const client = useSelector(selectClient);

  const updateMode = props.clientUpdate;

  const [form] = Form.useForm();

  const [clientFiscalStartMonth, setClientFiscalStartMonth] = useState("");
  const [busy, setBusy] = useState(true);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [secondMailToBox, setSecondMailToBox] = useState(true);
  const [collaborators, setCollaborators] = useState([]);

  function confirmWarning(values) {
    confirm({
      className: "fec-warning",
      width: 500,
      title: "Cette opération va supprimer les justificatifs déjà transmis par le client.",
      icon: <ExclamationCircleOutlined />,
      okText: "Oui supprimer",
      cancelText: "Accéder aux pièces reçues",
      maskClosable: false,
      content: (
        <Button
          className="other-action-btn cancel-fec-warning"
          onClick={(e) => {
            e.preventDefault();
            Modal.destroyAll();
          }}
        >
          Annuler
        </Button>
      ),
      onOk() {
        onFinish(values);
        Modal.destroyAll();
      },
      onCancel() {
        props.activeKeyImport("4");
        props.importActive(false);
      },
    });
  }

  useEffect(() => {
    if (hasFeature(accountingFirm.fec, accountingFirm.dataProvider, "BLOCK_CLIENT_EDIT"))
      setDisabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountingFirm.dataProvider]);

  useEffect(() => {
    (async () => {
      let res = await API.getUsersByAccountingFirmId(accountingFirm._id);
      if (res.status === 200) {
        res = await res.json();
        setCollaborators(res);
      }
    })();
  }, [accountingFirm._id]);

  async function postForm(formData) {
    let res;
    updateMode
      ? (res = await API.postUpdateClient(formData))
      : (res = await API.postImportFec(formData));

    setLoading(false);
    if (res.status === 201) {
      res = await res.json();

      if (!updateMode) logAction(70, 1, res._id);

      updateMode
        ? window.location.reload()
        : props.history.push({
            pathname: "/analysis/" + res._id + "/" + res.remoteId,
          });
    } else if (res.status === 409) {
      openNotification(
        "error",
        "Erreur lors de l'enregistrement, un client existe déjà avec ce nom."
      );
    } else {
      openNotification(
        "error",
        "Impossible de générer l'analyse. Êtes-vous sûr que les fichiers fournis sont bien des fichiers FEC ?"
      );
    }
  }

  const onFinish = async (values) => {
    if (
      values.fec_n === undefined &&
      values.fec_n_1 !== undefined &&
      accountingFirm.dataProvider !== "SAGE"
    ) {
      openNotification("error", "Veuillez charger un fichier FEC pour l'exercice en cours.");
    } else {
      setLoading(true);
      let formData = new FormData();
      formData.set("token", localStorage.getItem("token"));
      formData.set("update", updateMode); // true or false
      formData.set("clientId", props.clientId);
      formData.set("remoteId", updateMode ? client.remoteId : "");
      formData.set("accountingFirmId", accountingFirm._id);
      formData.set("clientName", values.clientName);
      formData.set("siren", values.clientSIREN || ""); // peut être undefined lorsque le champ n'apparait pas dans le formulaire
      if (!accountingFirm.connectedRights) {
        formData.set(
          "collaboratorsId",
          JSON.stringify(values.collaboratorsInCharge) || JSON.stringify([])
        );
      }
      formData.set("apeCode", values.apeCode || "");
      formData.set("address", values.address || "");
      formData.set("city", values.city || "");
      formData.set("postalCode", values.postalCode || "");
      formData.set("mailToBox", values.mailToBox || "");
      formData.set("mailToBoxSales", values.mailToBoxSales || "");
      formData.set("mailToBoxOther", values.mailToBoxOther || "");
      formData.set("pastYearComp", values.pastYearComp || false);

      if (accountingFirm.dataProvider !== "SAGE") {
        const formatDate = dayjs("2020-" + values.fiscalStartMonth).format("YYYY-MM");
        formData.set("fiscal_start_date", formatDate);
      }

      values.fiscalStartMonth !== undefined
        ? formData.set("fiscalStartMonth", values.fiscalStartMonth.toString())
        : formData.set("fiscalStartMonth", "");

      if (values.fec_n) {
        formData.append("file", await reader(values.fec_n[0].originFileObj), "FEC-N");
      }
      if (values.fec_n_1) {
        formData.append("file", await reader(values.fec_n_1[0].originFileObj), "FEC-N-1");
      }
      postForm(formData);
    }
  };

  const reader = (fileObj) =>
    new Promise((resolve) => {
      const fRd = new FileReader();
      fRd.readAsBinaryString(fileObj, "ISO-8859-1");
      fRd.onload = () => {
        resolve(
          new File([fRd.result], "file.txt", {
            type: "text/plain",
            lastModified: new Date(),
          })
        );
      };
    });

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  function showDelete() {
    confirm({
      title: "Êtes-vous sûr(e) de vouloir supprimer le dossier client ?",
      icon: <ExclamationCircleOutlined />,
      className: "modal-delete-fec",
      okText: "Oui supprimer",
      cancelText: "Non annuler",
      maskClosable: true,
      closable: true,
      onOk() {
        (async () => {
          await API.deleteClient(props.match.params.clientId);
          logAction(70, -1, props.match.params.clientId);
          props.history.push("/homepage");
        })();
      },
      onCancel() {
        Modal.destroyAll();
      },
    });
  }

  useEffect(() => {
    setSecondMailToBox(!client.mailToBoxSales && !client.mailToBoxOther);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showMailToBoxSales = (e) => {
    setSecondMailToBox(e.target.checked);
  };

  useEffect(() => {
    if (updateMode) {
      if (!client.fiscalStartMonth) {
        if (props.input !== undefined && props.input.client) {
          setClientFiscalStartMonth(
            dayjs(props.input.client.date_debut_exercice).format("M").toString()
          );
          setBusy(false);
        } else {
          setBusy(false);
        }
      } else {
        setClientFiscalStartMonth(
          dayjs("2020-" + client.fiscalStartMonth)
            .format("M")
            .toString()
        );
        setBusy(false);
      }
    } else {
      setBusy(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client._id]);

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientFiscalStartMonth]);

  return (
    <div className="import-container">
      {!busy && (!updateMode || client.status === "ready") ? (
        <>
          {updateMode ? null : <Nav {...props} />}
          <Row justify="center" className={updateMode ? null : "import"}>
            <Col xs={22} sm={22} md={22} lg={22} xl={22}>
              <Form
                form={form}
                name="fec"
                layout="vertical"
                onFinish={(values) => {
                  if (updateMode) {
                    if (
                      (values.fec_n !== undefined || values.fec_n_1 !== undefined) &&
                      props.nbrReceived > 0
                    ) {
                      confirmWarning(values);
                    } else {
                      onFinish(values);
                    }
                  } else {
                    onFinish(values);
                  }
                }}
                initialValues={{
                  clientName: updateMode ? client.name : "",
                  clientSIREN: updateMode ? client.siren : "",
                  collaboratorsInCharge: updateMode
                    ? accountingFirm.connectedRights
                      ? client.collaboratorsIdConnected
                      : client.collaboratorsId
                    : [],
                  apeCode: updateMode ? client.apeCode : "",
                  address: updateMode ? client.address : "",
                  city: updateMode ? client.city : "",
                  postalCode: updateMode ? client.postalCode : "",
                  fiscalStartMonth: clientFiscalStartMonth.toString(),
                  mailToBox: updateMode ? client.mailToBox : "",
                  mailToBoxSales: updateMode ? client.mailToBoxSales : "",
                  mailToBoxOther: updateMode ? client.mailToBoxOther : "",
                  pastYearComp: updateMode ? client.pastYearComp : false,
                }}
              >
                <Row className="top-box">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div className="title-import">
                      {updateMode ? "Paramètres client" : "Ajouter un client"}
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row className="import-submit-row">
                      {updateMode ? (
                        <Button
                          type="button"
                          className="other-action-btn return-button-fec"
                          onClick={() => {
                            props.activeKeyImport("1");
                            props.importActive(false);
                          }}
                        >
                          Annuler
                        </Button>
                      ) : null}
                      <Form.Item>
                        <Button
                          type="submit"
                          htmlType="submit"
                          className="call-action-btn link-button-fec"
                          loading={loading}
                        >
                          Valider <ArrowRightOutlined />
                        </Button>
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>

                <Row className="mid-box">
                  <Col xs={{ span: 20, offset: 2 }} lg={{ span: 8, offset: 3 }}>
                    <Title level={4}>Informations client</Title>
                    <Text className="import-input-text">Saisissez le nom du client</Text>
                    <Form.Item
                      name="clientName"
                      rules={[
                        {
                          required: true,
                          message: "Vous devez remplir le nom du client",
                        },
                      ]}
                    >
                      <Input
                        className="import-input"
                        placeholder="Nom du client"
                        disabled={disabled}
                      />
                    </Form.Item>
                    <Text className="import-input-text">
                      Saisissez le numéro SIREN (facultatif)
                    </Text>
                    <Form.Item name="clientSIREN">
                      <Input className="import-input" placeholder="Numéro SIREN" />
                    </Form.Item>
                    <Text className="import-input-text">
                      {accountingFirm.connectedRights
                        ? "Liste des utilisateurs ayant accès au dossier"
                        : "Saisissez les noms des collaborateurs en charge du dossier (facultatif)"}
                    </Text>
                    <Form.Item name="collaboratorsInCharge">
                      <Select
                        className="import-input collabs-select"
                        showSearch={true}
                        mode="multiple"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        popupClassName="ant-select-dropdown-zindex"
                        placement="bottomLeft"
                        dropdownAlign={{ overflow: { adjustY: false } }}
                        disabled={accountingFirm.connectedRights}
                      >
                        {collaborators.map((item) => (
                          <Option key={item._id}>{item.firstName + " " + item.lastName}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Text className="import-input-text">Saisissez le code APE (facultatif)</Text>
                    <Form.Item name="apeCode">
                      <Input className="import-input" placeholder="Code APE" disabled={disabled} />
                    </Form.Item>
                    <Text className="import-input-text">Saisissez l'adresse (facultatif)</Text>
                    <Form.Item name="address">
                      <Input className="import-input" placeholder="Adresse" disabled={disabled} />
                    </Form.Item>
                    <Text className="import-input-text">Saisissez la ville (facultatif)</Text>
                    <Form.Item name="city">
                      <Input className="import-input" placeholder="Ville" disabled={disabled} />
                    </Form.Item>
                    <Text className="import-input-text">Saisissez le code postal (facultatif)</Text>
                    <Form.Item name="postalCode">
                      <Input
                        className="import-input"
                        placeholder="Code postal"
                        disabled={disabled}
                      />
                    </Form.Item>

                    {accountingFirm.fec && updateMode ? (
                      <>
                        <Text className="import-input-text">
                          Saisissez le mois de début d'exercice (facultatif)
                        </Text>
                        <Form.Item
                          name="fiscalStartMonth"
                          className="month-select-form-item-import"
                        >
                          <Select
                            className="import-input month-select"
                            placeholder="Date"
                            allowClear={false}
                            placement="bottomLeft"
                            dropdownAlign={{ overflow: { adjustY: false } }}
                          >
                            <Option value="1">Janvier</Option>
                            <Option value="2">Février</Option>
                            <Option value="3">Mars</Option>
                            <Option value="4">Avril</Option>
                            <Option value="5">Mai</Option>
                            <Option value="6">Juin</Option>
                            <Option value="7">Juillet</Option>
                            <Option value="8">Août</Option>
                            <Option value="9">Septembre</Option>
                            <Option value="10">Octobre</Option>
                            <Option value="11">Novembre</Option>
                            <Option value="12">Décembre</Option>
                          </Select>
                        </Form.Item>
                        <CalendarOutlined className="calendar-icon" />
                      </>
                    ) : null}
                    {updateMode ? (
                      <Form.Item name="pastYearComp" valuePropName="checked">
                        <Checkbox>Analyse sans comparatif n-1</Checkbox>
                      </Form.Item>
                    ) : null}
                  </Col>
                  <Col xs={24} sm={0} md={0} lg={0} xl={0}>
                    <br />
                  </Col>
                  <Col xs={{ span: 20, offset: 2 }} lg={{ span: 8, offset: 2 }} className="black">
                    {accountingFirm.fec || accountingFirm.dataProvider === "SAGE" ? (
                      <>
                        <Title level={4}>Chargement des fichiers FEC</Title>
                        <div className="left-fec">
                          {accountingFirm.fec ? (
                            <Form.Item
                              className="import-input-text"
                              label="Charger le FEC de l'exercice en cours"
                            >
                              <Form.Item
                                name="fec_n"
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                                rules={[
                                  {
                                    required: updateMode ? false : true,
                                    message:
                                      "Vous devez ajouter le fichier FEC de l'exercice en cours",
                                  },
                                ]}
                              >
                                <Upload method="get" className="upload-input" maxCount={1}>
                                  <Button
                                    htmlType="button"
                                    className="upload-fec-btn"
                                    onClick={(e) => {
                                      e.preventDefault();
                                    }}
                                  >
                                    <UploadOutlined /> Parcourir
                                  </Button>
                                </Upload>
                              </Form.Item>
                            </Form.Item>
                          ) : null}

                          <Form.Item
                            className="import-input-text-fec-n-1"
                            label="Charger le FEC de l'exercice précédent"
                          >
                            <Form.Item
                              name="fec_n_1"
                              valuePropName="fileList"
                              getValueFromEvent={normFile}
                              rules={[
                                {
                                  required: false,
                                  message:
                                    "Vous devez ajouter le fichier FEC de l'exercice précédent",
                                },
                              ]}
                            >
                              <Upload method="get" className="upload-input" maxCount={1}>
                                <Button
                                  htmlType="button"
                                  className="upload-fec-btn"
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  <UploadOutlined /> Parcourir
                                </Button>
                              </Upload>
                            </Form.Item>
                            {updateMode ? (
                              <>
                                <Card className="fec-warning-card">
                                  <ExclamationCircleOutlined style={{ color: "#faad14" }} />
                                  <p>
                                    La mise à jour des fichiers FEC a pour effet de supprimer les
                                    justificatifs déjà transmis par le client.
                                  </p>
                                </Card>
                              </>
                            ) : null}
                          </Form.Item>
                        </div>
                      </>
                    ) : null}
                    {accountingFirm.mailToBox ? (
                      <>
                        <Title level={4}>Mail to Box</Title>
                        <Text className="import-input-text">
                          {!secondMailToBox
                            ? " Saisissez l’adresse pour les achats"
                            : "Saisissez l’adresse Mail to Box"}
                        </Text>
                        <Form.Item
                          name="mailToBox"
                          rules={[
                            {
                              required: !secondMailToBox,
                              message: "Veuillez renseigner une adresse pour les achats",
                            },
                          ]}
                        >
                          <Input className="import-input" placeholder="Mail to Box" />
                        </Form.Item>
                        <Checkbox
                          onClick={(e) => e.stopPropagation()}
                          checked={secondMailToBox}
                          onChange={(e) => showMailToBoxSales(e)}
                        >
                          Adresse unique
                        </Checkbox>
                        {!secondMailToBox ? (
                          <>
                            <Text className="import-input-text mail-sales">
                              Saisissez l’adresse pour les ventes (si non renseigné : renvoi vers
                              "Achats")
                            </Text>
                            <Form.Item name="mailToBoxSales">
                              <Input className="import-input" placeholder="Mail to Box ventes" />
                            </Form.Item>
                            <Text className="import-input-text mail-sales">
                              Saisissez l’adresse pour les autres pièces (si non renseigné : renvoi
                              vers "Achats")
                            </Text>
                            <Form.Item name="mailToBoxOther">
                              <Input className="import-input" placeholder="Mail to Box autres" />
                            </Form.Item>
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </Col>
                  {updateMode ? (
                    <Col xs={{ span: 24 }} lg={{ span: 21 }}>
                      <Button
                        htmlType="button"
                        className="other-action-btn delete-fec-btn"
                        onClick={(e) => {
                          e.preventDefault();
                          showDelete();
                        }}
                      >
                        <DeleteOutlined /> Supprimer le client
                      </Button>
                    </Col>
                  ) : null}
                </Row>

                <div style={{ clear: "both" }}></div>
              </Form>
              <Row className="bottom-box"></Row>
            </Col>
          </Row>
        </>
      ) : null}
      {updateMode ? null : <Footer />}
    </div>
  );
}

export default Import;
